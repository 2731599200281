export const areaCodeCountry = [
  {
    area: "af",
    country: "Afghanistan (+93)",
    pos: "-1px -3180px",
  },
  {
    area: "al",
    country: "Albania (+355)",
    pos: "-1px -1310px",
  },
  {
    area: "dz",
    country: "Algeria (+213)",
    pos: "-1px -681px",
  },
  {
    area: "as",
    country: "American Samoa (+1)",
    pos: "-1px -2058px",
  },
  {
    area: "ad",
    country: "Andorra (+376)",
    pos: "-1px -766px",
  },
  {
    area: "ao",
    country: "Angola (+244)",
    pos: "-1px -2636px",
  },
  {
    area: "ai",
    country: "Anguilla (+1)",
    pos: "-1px -2687px",
  },
  {
    area: "ag",
    country: "Antigua & Barbuda (+1)",
    pos: "-1px -1140px",
  },
  {
    area: "ar",
    country: "Argentina (+54)",
    pos: "-1px -3282px",
  },
  {
    area: "am",
    country: "Armenia (+374)",
    pos: "-1px -205px",
  },
  {
    area: "aw",
    country: "Aruba (+297)",
    pos: "-1px -1021px",
  },
  {
    area: "ac",
    country: "Ascension Island (+247)",
    pos: "-1px -86px",
  },
  {
    area: "au",
    country: "Australia (+61)",
    pos: "-1px -2279px",
  },
  {
    area: "at",
    country: "Austria (+43)",
    pos: "-1px -1735px",
  },
  {
    area: "az",
    country: "Azerbaijan (+994)",
    pos: "-1px -1599px",
  },
  {
    area: "bs",
    country: "Bahamas (+1)",
    pos: "-1px -460px",
  },
  {
    area: "bh",
    country: "Bahrain (+973)",
    pos: "-1px -1956px",
  },
  {
    area: "bd",
    country: "Bangladesh (+880)",
    pos: "-1px -2364px",
  },
  {
    area: "bb",
    country: "Barbados (+1)",
    pos: "-1px -2075px",
  },
  {
    area: "by",
    country: "Belarus (+375)",
    pos: "-1px -1412px",
  },
  {
    area: "be",
    country: "Belgium (+32)",
    pos: "-1px -1px",
  },
  {
    area: "bz",
    country: "Belize (+501)",
    pos: "-1px -613px",
  },
  {
    area: "bj",
    country: "Benin (+229)",
    pos: "-1px -1684px",
  },
  {
    area: "bm",
    country: "Bermuda (+1)",
    pos: "-1px -2585px",
  },
  {
    area: "bt",
    country: "Bhutan (+975)",
    pos: "-1px -2483px",
  },
  {
    area: "bo",
    country: "Bolivia (+591)",
    pos: "-1px -2177px",
  },
  {
    area: "ba",
    country: "Bosnia & Herzegovina (+387)",
    pos: "-1px -2092px",
  },
  {
    area: "bw",
    country: "Botswana (+267)",
    pos: "-1px -3724px",
  },
  {
    area: "br",
    country: "Brazil (+55)",
    pos: "-1px -1004px",
  },
  {
    area: "io",
    country: "British Indian Ocean Territory (+246)",
    pos: "-1px -86px",
  },
  {
    area: "vg",
    country: "British Virgin Islands (+1)",
    pos: "-1px -1854px",
  },
  {
    area: "bn",
    country: "Brunei (+673)",
    pos: "-1px -2228px",
  },
  {
    area: "bg",
    country: "Bulgaria (+359)",
    pos: "-1px -3537px",
  },
  {
    area: "bf",
    country: "Burkina Faso (+226)",
    pos: "-1px -953px",
  },
  {
    area: "bi",
    country: "Burundi (+257)",
    pos: "-1px -2551px",
  },
  {
    area: "kh",
    country: "Cambodia (+855)",
    pos: "-1px -290px",
  },
  {
    area: "cm",
    country: "Cameroon (+237)",
    pos: "-1px -2806px",
  },
  {
    area: "ca",
    country: "Canada (+1)",
    pos: "-1px -1803px",
  },
  {
    area: "cv",
    country: "Cape Verde (+238)",
    pos: "-1px -3639px",
  },
  {
    area: "bq",
    country: "Caribbean Netherlands (+599)",
    pos: "-1px -3741px",
  },
  {
    area: "ky",
    country: "Cayman Islands (+1)",
    pos: "-1px -375px",
  },
  {
    area: "cf",
    country: "Central African Republic (+236)",
    pos: "-1px -2466px",
  },
  {
    area: "td",
    country: "Chad (+235)",
    pos: "-1px -1055px",
  },
  {
    area: "cl",
    country: "Chile (+56)",
    pos: "-1px -1752px",
  },
  {
    area: "cn",
    country: "China (+86)",
    pos: "-1px -1072px",
  },
  {
    area: "co",
    country: "Colombia (+57)",
    pos: "-1px -409px",
  },
  {
    area: "km",
    country: "Comoros (+269)",
    pos: "-1px -1871px",
  },
  {
    area: "cg",
    country: "Congo - Brazzaville (+242)",
    pos: "-1px -2398px",
  },
  {
    area: "cd",
    country: "Congo - Kinshasa (+243)",
    pos: "-1px -1990px",
  },
  {
    area: "ck",
    country: "Cook Islands (+682)",
    pos: "-1px -3112px",
  },
  {
    area: "cr",
    country: "Costa Rica (+506)",
    pos: "-1px -2857px",
  },
  {
    area: "ci",
    country: "Côte d’Ivoire (+225)",
    pos: "-1px -2194px",
  },
  {
    area: "hr",
    country: "Croatia (+385)",
    pos: "-1px -1174px",
  },
  {
    area: "cu",
    country: "Cuba (+53)",
    pos: "-1px -987px",
  },
  {
    area: "cw",
    country: "Curaçao (+599)",
    pos: "-1px -3758px",
  },
  {
    area: "cy",
    country: "Cyprus (+357)",
    pos: "-1px -732px",
  },
  {
    area: "cz",
    country: "Czechia (+420)",
    pos: "-1px -3095px",
  },
  {
    area: "dk",
    country: "Denmark (+45)",
    pos: "-1px -1820px",
  },
  {
    area: "dj",
    country: "Djibouti (+253)",
    pos: "-1px -2874px",
  },
  {
    area: "dm",
    country: "Dominica (+1)",
    pos: "-1px -3350px",
  },
  {
    area: "do",
    country: "Dominican Republic (+1)",
    pos: "-1px -2007px",
  },
  {
    area: "ec",
    country: "Ecuador (+593)",
    pos: "-1px -1531px",
  },
  {
    area: "eg",
    country: "Egypt (+20)",
    pos: "-1px -3027px",
  },
  {
    area: "sv",
    country: "El Salvador (+503)",
    pos: "-1px -2160px",
  },
  {
    area: "gq",
    country: "Equatorial Guinea (+240)",
    pos: "-1px -1973px",
  },
  {
    area: "er",
    country: "Eritrea (+291)",
    pos: "-1px -936px",
  },
  {
    area: "ee",
    country: "Estonia (+372)",
    pos: "-1px -3333px",
  },
  {
    area: "sz",
    country: "Eswatini (+268)",
    pos: "-1px -3129px",
  },
  {
    area: "et",
    country: "Ethiopia (+251)",
    pos: "-1px -3367px",
  },
  {
    area: "fk",
    country: "Falkland Islands (Islas Malvinas) (+500)",
    pos: "-1px -3809px",
  },
  {
    area: "fo",
    country: "Faroe Islands (+298)",
    pos: "-1px -1429px",
  },
  {
    area: "fj",
    country: "Fiji (+679)",
    pos: "-1px -2500px",
  },
  {
    area: "fi",
    country: "Finland (+358)",
    pos: "-1px -2568px",
  },
  {
    area: "fr",
    country: "France (+33)",
    pos: "-1px -324px",
  },
  {
    area: "gf",
    country: "French Guiana (+594)",
    pos: "-1px -324px",
  },
  {
    area: "pf",
    country: "French Polynesia (+689)",
    pos: "-1px -2262px",
  },
  {
    area: "ga",
    country: "Gabon (+241)",
    pos: "-1px -1157px",
  },
  {
    area: "gm",
    country: "Gambia (+220)",
    pos: "-1px -817px",
  },
  {
    area: "ge",
    country: "Georgia (+995)",
    pos: "-1px -1123px",
  },
  {
    area: "de",
    country: "Germany (+49)",
    pos: "-1px -3452px",
  },
  {
    area: "gh",
    country: "Ghana (+233)",
    pos: "-1px -2891px",
  },
  {
    area: "gi",
    country: "Gibraltar (+350)",
    pos: "-1px -341px",
  },
  {
    area: "gr",
    country: "Greece (+30)",
    pos: "-1px -188px",
  },
  {
    area: "gl",
    country: "Greenland (+299)",
    pos: "-1px -2347px",
  },
  {
    area: "gd",
    country: "Grenada (+1)",
    pos: "-1px -3316px",
  },
  {
    area: "gp",
    country: "Guadeloupe (+590)",
    pos: "-1px -511px",
  },
  {
    area: "gu",
    country: "Guam (+1)",
    pos: "-1px -3265px",
  },
  {
    area: "gt",
    country: "Guatemala (+502)",
    pos: "-1px -1208px",
  },
  {
    area: "gn",
    country: "Guinea (+224)",
    pos: "-1px -3520px",
  },
  {
    area: "gw",
    country: "Guinea-Bissau (+245)",
    pos: "-1px -2602px",
  },
  {
    area: "gy",
    country: "Guyana (+592)",
    pos: "-1px -1038px",
  },
  {
    area: "ht",
    country: "Haiti (+509)",
    pos: "-1px -392px",
  },
  {
    area: "hn",
    country: "Honduras (+504)",
    pos: "-1px -2959px",
  },
  {
    area: "hk",
    country: "Hong Kong (+852)",
    pos: "-1px -3707px",
  },
  {
    area: "hu",
    country: "Hungary (+36)",
    pos: "-1px -902px",
  },
  {
    area: "is",
    country: "Iceland (+354)",
    pos: "-1px -2704px",
  },
  {
    area: "in",
    country: "India (+91)",
    pos: "-1px -2245px",
  },
  {
    area: "id",
    country: "Indonesia (+62)",
    pos: "-1px -2653px",
  },
  {
    area: "ir",
    country: "Iran (+98)",
    pos: "-1px -2738px",
  },
  {
    area: "iq",
    country: "Iraq (+964)",
    pos: "-1px -851px",
  },
  {
    area: "ie",
    country: "Ireland (+353)",
    pos: "-1px -2670px",
  },
  {
    area: "il",
    country: "Israel (+972)",
    pos: "-1px -426px",
  },
  {
    area: "it",
    country: "Italy (+39)",
    pos: "-1px -154px",
  },
  {
    area: "jm",
    country: "Jamaica (+1)",
    pos: "-1px -2296px",
  },
  {
    area: "jp",
    country: "Japan (+81)",
    pos: "-1px -528px",
  },
  {
    area: "jo",
    country: "Jordan (+962)",
    pos: "-1px -1905px",
  },
  {
    area: "kz",
    country: "Kazakhstan (+7)",
    pos: "-1px -1565px",
  },
  {
    area: "ke",
    country: "Kenya (+254)",
    pos: "-1px -3605px",
  },
  {
    area: "ki",
    country: "Kiribati (+686)",
    pos: "-1px -477px",
  },
  {
    area: "xk",
    country: "Kosovo (+383)",
    pos: "-1px -3860px",
  },
  {
    area: "kw",
    country: "Kuwait (+965)",
    pos: "-1px -3435px",
  },
  {
    area: "kg",
    country: "Kyrgyzstan (+996)",
    pos: "-1px -2143px",
  },
  {
    area: "la",
    country: "Laos (+856)",
    pos: "-1px -562px",
  },
  {
    area: "lv",
    country: "Latvia (+371)",
    pos: "-1px -2619px",
  },
  {
    area: "lb",
    country: "Lebanon (+961)",
    pos: "-1px -1616px",
  },
  {
    area: "ls",
    country: "Lesotho (+266)",
    pos: "-1px -3010px",
  },
  {
    area: "lr",
    country: "Liberia (+231)",
    pos: "-1px -2823px",
  },
  {
    area: "ly",
    country: "Libya (+218)",
    pos: "-1px -137px",
  },
  {
    area: "li",
    country: "Liechtenstein (+423)",
    pos: "-1px -1276px",
  },
  {
    area: "lt",
    country: "Lithuania (+370)",
    pos: "-1px -1446px",
  },
  {
    area: "lu",
    country: "Luxembourg (+352)",
    pos: "-1px -1922px",
  },
  {
    area: "mo",
    country: "Macao (+853)",
    pos: "-1px -3554px",
  },
  {
    area: "mg",
    country: "Madagascar (+261)",
    pos: "-1px -1667px",
  },
  {
    area: "mw",
    country: "Malawi (+265)",
    pos: "-1px -2942px",
  },
  {
    area: "my",
    country: "Malaysia (+60)",
    pos: "-1px -2517px",
  },
  {
    area: "mv",
    country: "Maldives (+960)",
    pos: "-1px -800px",
  },
  {
    area: "ml",
    country: "Mali (+223)",
    pos: "-1px -3469px",
  },
  {
    area: "mt",
    country: "Malta (+356)",
    pos: "-1px -2041px",
  },
  {
    area: "mh",
    country: "Marshall Islands (+692)",
    pos: "-1px -1463px",
  },
  {
    area: "mq",
    country: "Martinique (+596)",
    pos: "-1px -239px",
  },
  {
    area: "mr",
    country: "Mauritania (+222)",
    pos: "-1px -307px",
  },
  {
    area: "mu",
    country: "Mauritius (+230)",
    pos: "-1px -2993px",
  },
  {
    area: "mx",
    country: "Mexico (+52)",
    pos: "-1px -2755px",
  },
  {
    area: "fm",
    country: "Micronesia (+691)",
    pos: "-1px -2313px",
  },
  {
    area: "md",
    country: "Moldova (+373)",
    pos: "-1px -3690px",
  },
  {
    area: "mc",
    country: "Monaco (+377)",
    pos: "-1px -1191px",
  },
  {
    area: "mn",
    country: "Mongolia (+976)",
    pos: "-1px -3503px",
  },
  {
    area: "me",
    country: "Montenegro (+382)",
    pos: "-1px -2976px",
  },
  {
    area: "ms",
    country: "Montserrat (+1)",
    pos: "-1px -749px",
  },
  {
    area: "ma",
    country: "Morocco (+212)",
    pos: "-1px -3214px",
  },
  {
    area: "mz",
    country: "Mozambique (+258)",
    pos: "-1px -834px",
  },
  {
    area: "mm",
    country: "Myanmar (Burma) (+95)",
    pos: "-1px -18px",
  },
  {
    area: "na",
    country: "Namibia (+264)",
    pos: "-1px -2534px",
  },
  {
    area: "nr",
    country: "Nauru (+674)",
    pos: "-1px -2330px",
  },
  {
    area: "np",
    country: "Nepal (+977)",
    pos: "-1px -120px",
  },
  {
    area: "nl",
    country: "Netherlands (+31)",
    pos: "-1px -1888px",
  },
  {
    area: "nc",
    country: "New Caledonia (+687)",
    pos: "-1px -1650px",
  },
  {
    area: "nz",
    country: "New Zealand (+64)",
    pos: "-1px -2024px",
  },
  {
    area: "ni",
    country: "Nicaragua (+505)",
    pos: "-1px -171px",
  },
  {
    area: "ne",
    country: "Niger (+227)",
    pos: "-1px -715px",
  },
  {
    area: "ng",
    country: "Nigeria (+234)",
    pos: "-1px -3418px",
  },
  {
    area: "nu",
    country: "Niue (+683)",
    pos: "-1px -2840px",
  },
  {
    area: "nf",
    country: "Norfolk Island (+672)",
    pos: "-1px -256px",
  },
  {
    area: "kp",
    country: "North Korea (+850)",
    pos: "-1px -2415px",
  },
  {
    area: "mk",
    country: "North Macedonia (+389)",
    pos: "-1px -1769px",
  },
  {
    area: "mp",
    country: "Northern Mariana Islands (+1)",
    pos: "-1px -919px",
  },
  {
    area: "no",
    country: "Norway (+47)",
    pos: "-1px -1089px",
  },
  {
    area: "om",
    country: "Oman (+968)",
    pos: "-1px -3384px",
  },
  {
    area: "pk",
    country: "Pakistan (+92)",
    pos: "-1px -2772px",
  },
  {
    area: "pw",
    country: "Palau (+680)",
    pos: "-1px -273px",
  },
  {
    area: "ps",
    country: "Palestine (+970)",
    pos: "-1px -1548px",
  },
  {
    area: "pa",
    country: "Panama (+507)",
    pos: "-1px -1106px",
  },
  {
    area: "pg",
    country: "Papua New Guinea (+675)",
    pos: "-1px -1939px",
  },
  {
    area: "py",
    country: "Paraguay (+595)",
    pos: "-1px -3231px",
  },
  {
    area: "pe",
    country: "Peru (+51)",
    pos: "-1px -1225px",
  },
  {
    area: "ph",
    country: "Philippines (+63)",
    pos: "-1px -2432px",
  },
  {
    area: "pl",
    country: "Poland (+48)",
    pos: "-1px -1514px",
  },
  {
    area: "pt",
    country: "Portugal (+351)",
    pos: "-1px -664px",
  },
  {
    area: "pr",
    country: "Puerto Rico (+1)",
    pos: "-1px -596px",
  },
  {
    area: "qa",
    country: "Qatar (+974)",
    pos: "-1px -579px",
  },
  {
    area: "re",
    country: "Réunion (+262)",
    pos: "-1px -324px",
  },
  {
    area: "ro",
    country: "Romania (+40)",
    pos: "-1px -885px",
  },
  {
    area: "ru",
    country: "Russia (+7)",
    pos: "-1px -868px",
  },
  {
    area: "rw",
    country: "Rwanda (+250)",
    pos: "-1px -3673px",
  },
  {
    area: "ws",
    country: "Samoa (+685)",
    pos: "-1px -3163px",
  },
  {
    area: "sm",
    country: "San Marino (+378)",
    pos: "-1px -2908px",
  },
  {
    area: "st",
    country: "São Tomé & Príncipe (+239)",
    pos: "-1px -3299px",
  },
  {
    area: "sa",
    country: "Saudi Arabia (+966)",
    pos: "-1px -52px",
  },
  {
    area: "sn",
    country: "Senegal (+221)",
    pos: "-1px -2925px",
  },
  {
    area: "rs",
    country: "Serbia (+381)",
    pos: "-1px -3401px",
  },
  {
    area: "sc",
    country: "Seychelles (+248)",
    pos: "-1px -1327px",
  },
  {
    area: "sl",
    country: "Sierra Leone (+232)",
    pos: "-1px -970px",
  },
  {
    area: "sg",
    country: "Singapore (+65)",
    pos: "-1px -35px",
  },
  {
    area: "sx",
    country: "Sint Maarten (+1)",
    pos: "-1px -3826px",
  },
  {
    area: "sk",
    country: "Slovakia (+421)",
    pos: "-1px -3044px",
  },
  {
    area: "si",
    country: "Slovenia (+386)",
    pos: "-1px -1582px",
  },
  {
    area: "sb",
    country: "Solomon Islands (+677)",
    pos: "-1px -1361px",
  },
  {
    area: "so",
    country: "Somalia (+252)",
    pos: "-1px -1786px",
  },
  {
    area: "za",
    country: "South Africa (+27)",
    pos: "-1px -3248px",
  },
  {
    area: "kr",
    country: "South Korea (+82)",
    pos: "-1px -3078px",
  },
  {
    area: "ss",
    country: "South Sudan (+211)",
    pos: "-1px -3775px",
  },
  {
    area: "es",
    country: "Spain (+34)",
    pos: "-1px -1480px",
  },
  {
    area: "lk",
    country: "Sri Lanka (+94)",
    pos: "-1px -3622px",
  },
  {
    area: "bl",
    country: "St. Barthélemy (+590)",
    pos: "-1px -324px",
  },
  {
    area: "sh",
    country: "St. Helena (+290)",
    pos: "-1px -630px",
  },
  {
    area: "kn",
    country: "St. Kitts & Nevis (+1)",
    pos: "-1px -103px",
  },
  {
    area: "lc",
    country: "St. Lucia (+1)",
    pos: "-1px -1837px",
  },
  {
    area: "mf",
    country: "St. Martin (+590)",
    pos: "-1px -86px",
  },
  {
    area: "pm",
    country: "St. Pierre & Miquelon (+508)",
    pos: "-1px -1378px",
  },
  {
    area: "vc",
    country: "St. Vincent & Grenadines (+1)",
    pos: "-1px -3588px",
  },
  {
    area: "sd",
    country: "Sudan (+249)",
    pos: "-1px -443px",
  },
  {
    area: "sr",
    country: "Suriname (+597)",
    pos: "-1px -3656px",
  },
  {
    area: "se",
    country: "Sweden (+46)",
    pos: "-1px -494px",
  },
  {
    area: "ch",
    country: "Switzerland (+41)",
    pos: "-1px -1718px",
  },
  {
    area: "sy",
    country: "Syria (+963)",
    pos: "-1px -2449px",
  },
  {
    area: "tw",
    country: "Taiwan (+886)",
    pos: "-1px -647px",
  },
  {
    area: "tj",
    country: "Tajikistan (+992)",
    pos: "-1px -222px",
  },
  {
    area: "tz",
    country: "Tanzania (+255)",
    pos: "-1px -3146px",
  },
  {
    area: "th",
    country: "Thailand (+66)",
    pos: "-1px -1242px",
  },
  {
    area: "tl",
    country: "Timor-Leste (+670)",
    pos: "-1px -3843px",
  },
  {
    area: "tg",
    country: "Togo (+228)",
    pos: "-1px -783px",
  },
  {
    area: "tk",
    country: "Tokelau (+690)",
    pos: "-1px -3792px",
  },
  {
    area: "to",
    country: "Tonga (+676)",
    pos: "-1px -1395px",
  },
  {
    area: "tt",
    country: "Trinidad & Tobago (+1)",
    pos: "-1px -545px",
  },
  {
    area: "tn",
    country: "Tunisia (+216)",
    pos: "-1px -698px",
  },
  {
    area: "tr",
    country: "Türkiye (+90)",
    pos: "-1px -2126px",
  },
  {
    area: "tm",
    country: "Turkmenistan (+993)",
    pos: "-1px -3486px",
  },
  {
    area: "tc",
    country: "Turks & Caicos Islands (+1)",
    pos: "-1px -1701px",
  },
  {
    area: "tv",
    country: "Tuvalu (+688)",
    pos: "-1px -358px",
  },
  {
    area: "vi",
    country: "U.S. Virgin Islands (+1)",
    pos: "-1px -2381px",
  },
  {
    area: "ug",
    country: "Uganda (+256)",
    pos: "-1px -1497px",
  },
  {
    area: "ua",
    country: "Ukraine (+380)",
    pos: "-1px -2721px",
  },
  {
    area: "ae",
    country: "United Arab Emirates (+971)",
    pos: "-1px -3061px",
  },
  {
    area: "gb",
    country: "United Kingdom (+44)",
    pos: "-1px -86px",
  },
  {
    area: "us",
    country: "United States (+1)",
    pos: "-1px -69px",
  },
  {
    area: "uy",
    country: "Uruguay (+598)",
    pos: "-1px -3571px",
  },
  {
    area: "uz",
    country: "Uzbekistan (+998)",
    pos: "-1px -1293px",
  },
  {
    area: "vu",
    country: "Vanuatu (+678)",
    pos: "-1px -1633px",
  },
  {
    area: "va",
    country: "Vatican City (+39)",
    pos: "-1px -3197px",
  },
  {
    area: "ve",
    country: "Venezuela (+58)",
    pos: "-1px -1344px",
  },
  {
    area: "vn",
    country: "Vietnam (+84)",
    pos: "-1px -1259px",
  },
  {
    area: "wf",
    country: "Wallis & Futuna (+681)",
    pos: "-1px -324px",
  },
  {
    area: "ye",
    country: "Yemen (+967)",
    pos: "-1px -2211px",
  },
  {
    area: "zm",
    country: "Zambia (+260)",
    pos: "-1px -2109px",
  },
  {
    area: "zw",
    country: "Zimbabwe (+263)",
    pos: "-1px -2789px",
  },
];
