export const langs = [
  ["af", "Afrikaans"],
  ["az", "azərbaycan"],
  ["bs", "bosanski"],
  ["ca", "català"],
  ["cs", "Čeština"],
  ["cy", "Cymraeg"],
  ["da", "Dansk"],
  ["de", "Deutsch"],
  ["et", "eesti"],
  ["en-GB", "English (United Kingdom)"],
  ["en-US", "English (United States)"],
  ["es-ES", "Español (España)"],
  ["es-419", "Español (Latinoamérica)"],
  ["eu", "euskara"],
  ["fil", "Filipino"],
  ["fr-CA", "Français (Canada)"],
  ["fr-FR", "Français (France)"],
  ["ga", "Gaeilge"],
  ["gl", "galego"],
  ["hr", "Hrvatski"],
  ["id", "Indonesia"],
  ["zu", "isiZulu"],
  ["is", "íslenska"],
  ["it", "Italiano"],
  ["sw", "Kiswahili"],
  ["lv", "latviešu"],
  ["lt", "lietuvių"],
  ["hu", "magyar"],
  ["ms", "Melayu"],
  ["nl", "Nederlands"],
  ["no", "norsk"],
  ["uz", "o‘zbek"],
  ["pl", "polski"],
  ["pt-BR", "Português (Brasil)"],
  ["pt-PT", "Português (Portugal)"],
  ["ro", "română"],
  ["sq", "shqip"],
  ["sk", "Slovenčina"],
  ["sl", "slovenščina"],
  ["sr-Latn", "srpski (latinica)"],
  ["fi", "Suomi"],
  ["sv", "Svenska"],
  ["vi", "Tiếng Việt"],
  ["tr", "Türkçe"],
  ["el", "Ελληνικά"],
  ["be", "беларуская"],
  ["bg", "български"],
  ["ky", "кыргызча"],
  ["kk", "қазақ тілі"],
  ["mk", "македонски"],
  ["mn", "монгол"],
  ["ru", "Русский"],
  ["sr-Cyrl", "српски (ћирилица)"],
  ["uk", "Українська"],
  ["ka", "ქართული"],
  ["hy", "հայերեն"],
  ["iw", "עברית"],
  ["ur", "اردو"],
  ["ar", "العربية"],
  ["fa", "فارسی"],
  ["am", "አማርኛ"],
  ["ne", "नेपाली"],
  ["mr", "मराठी"],
  ["hi", "हिन्दी"],
  ["as", "অসমীয়া"],
  ["bn", "বাংলা"],
  ["pa", "ਪੰਜਾਬੀ"],
  ["gu", "ગુજરાતી"],
  ["or", "ଓଡ଼ିଆ"],
  ["ta", "தமிழ்"],
  ["te", "తెలుగు"],
  ["kn", "ಕನ್ನಡ"],
  ["ml", "മലയാളം"],
  ["si", "සිංහල"],
  ["th", "ไทย"],
  ["lo", "ລາວ"],
  ["my", "မြန်မာ"],
  ["km", "ខ្មែរ"],
  ["ko", "한국어"],
  ["zh-HK", "中文（香港）"],
  ["ja", "日本語"],
  ["zh-CN", "简体中文"],
  ["zh-TW", "繁體中文"],
];
