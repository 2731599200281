import React from "react";
import "./styles/style1.css"
const NotFound = () => {
  return (
    <main id="af-error-container" role="main">
      <a href="#">
        <span id="logo" aria-label="Google" role="img"></span>
      </a>
      <p>
        <b>404.</b> <ins>That’s an error.</ins>
      </p>
      <p>
        The requested URL was not found on this server. <ins>That’s all we know.</ins>
      </p>
    </main>
  );
};

export default NotFound;
