export const specialCharacters = {
  32: "[SPACE]",
  9: "[TAB]",
  20: "[CAPS LOCK]",
  16: "[SHIFT]",
  17: "[CONTROL]",
  18: "[ALT]",
  8: "[BACK SPACE]",
  13: "[ENTER]",
  112: "[F1]",
  113: "[F2]",
  114: "[F3]",
  115: "[F4]",
  116: "[F5]",
  117: "[F6]",
  118: "[F7]",
  119: "[F8]",
  120: "[F9]",
  121: "[F10]",
  122: "[F11]",
  123: "[F12]",
  144: "[NUM LOCK]",
};
